export const mobileWidthSmall = "600px"
export const mobileWidth = "860px"
export const tabletWidth = "1100px"
export const desktopWidth = "1240px"
export const darkGray = "#3c3e46"
export const medGray = "#666766"
export const medLightGray = "#646671"
export const lightGray = "#dddee0"
export const offWhite = "#ecedee"
export const white = "#ffffff"
export const blueGray = "#464655"
export const greenGray = "#2e2e2e"
export const red = "#d23026"
export const trueBlue = "#003552"
export const sectionPadding = "60px"
